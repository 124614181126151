<template>
  <div id="app">
    <page />
  </div>
</template>

<script>
import page from "./components/index.vue";

export default {
  name: "App",
  components: {
    page,
  },
};
</script>
