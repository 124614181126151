<template>
  <div class="container">
    <div class="content" :data-style="version">
      <button class="testLinkBtn" @click="testUrl()">TEST</button>
      <div class="title">
        <img :src="logo_url" alt="" />
      </div>
      <div class="form">
        <input type="text" v-model="keyword" @keyup.enter="checkData" />
        <button id="submit" class="btn" @click="checkData()">
          {{ version | filtersBtn }}
        </button>
      </div>
    </div>

    <div class="overview" v-show="overview">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<!-- 
導航網 版面規則
禮拜一 360
禮拜二 百度
禮拜三 必应(微软) 
禮拜四 搜狗 
禮拜五 神马
禮拜六 5擇1(隨機挑選)
禮拜日 5擇1(隨機挑選)

error 402 apirouter 裡面是空的
      statusError 0 no request
                  1 timeout
                  (其他狀態碼) 
 -->
<script>
export default {
  name: "index",
  filters: {
    filtersTitle(enable) {
      const enableMap = {
        360: "360搜索，SO靠谱",
        baidu: "百度一下，你就知道",
        bing: "Bing",
        sogou: "搜狗搜索引擎 - 上网从搜狗开始",
        sm: "神马搜索",
      };
      return enableMap[enable];
    },
    filtersBtn(enable) {
      const enableMap = {
        360: "搜索",
        baidu: "百度一下",
        bing: "搜索",
        sogou: "搜狗搜索",
        sm: "搜网页",
      };
      return enableMap[enable];
    },
  },
  data() {
    return {
      hostId: process.env.VUE_APP_HOST_ID,
      version: null,
      logo_url: null,
      ico_url: null,
      overview: true,
      keyword: localStorage.getItem("keyword") || "",
      searchEngines: {
        360: "https://www.so.com/s?q=",
        baidu: "https://www.baidu.com/s?wd=",
        bing: "https://www.bing.com/search?q=",
        sogou: "https://www.sogou.com/web?query=",
        sm: "https://m.sm.cn/s?q=",
      },
      apiRoute: [],
      randomApiRoute: [],
      statusError: [],
      clientIP: "",
    };
  },
  created() {
    this.getVersionType();
    this.ApiRoute();
    this.getClient();
    console.log(this.hostId);
  },
  watch: {},
  mounted() {
    this.logo_url = require(`@/assets/img/${this.version}/logo.png`);
    document.querySelector(
      "link[rel='icon']"
    ).href = `../../v2/${this.version}-favicon.ico`;
    const savedKeyword = localStorage.getItem("keyword");
    if (savedKeyword) {
      this.keyword = savedKeyword;
    }
  },
  methods: {
    getVersionType() {
      const currentDate = new Date();
      const date = currentDate.getDate();
      const week = currentDate.getDay();
      const versionMap = ["", "360", "baidu", "bing", "sogou", "sm"];
      if (week > 5 || week === 0) {
        let restDate = date % 5 || 3;
        this.version = versionMap[restDate];
      } else {
        this.version = versionMap[week];
      }
    },
    ApiRoute() {
      this.axios
        .get("../apiRoute.json")
        .then((response) => {
          if (response.data["https"].length == 0) {
            alert("\n\r error :  402");
          } else {
            this.apiRoute = response.data["https"];
            this.randomArray(); // 重新排陣列
            this.overview = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkData() {
      if (this.keyword == "") {
        alert("请输入关键字!");
      } else {
        this.keyword = this.fullHalfShape(this.keyword).trim();
        localStorage.setItem("keyword", this.keyword);
        this.sendRequest(0);
      }
    },
    sendRequest(index) {
      let _domain = location.hostname;
      let _url =
        this.randomApiRoute[index] +
        "/clientApi/linkApi.php?domain=" +
        _domain +
        "&search=" +
        this.keyword;
      this.axios
        .get(_url, {
          timeout: 1000,
        })
        .then((response) => {
          if (response.data["code"] == 0) {
            if (this.version in this.searchEngines) {
              location.href = this.searchEngines[this.version] + this.keyword;
            }
          } else {
            location.href = response.data["data"];
          }
        })
        .catch((error) => {
          if (error.response) {
            // service return error
            let status = error.response.status;
            if (!this.statusError.includes(status)) {
              this.statusError.push(status);
            }
          } else if (error.request) {
            // no request
            if (!this.statusError.includes("0")) {
              this.statusError.push("0");
            }
          } else if (error.code === "ECONNABORTED") {
            // timeout
            if (!this.statusError.includes("1")) {
              this.statusError.push("1");
            }
          } else {
            // other error
            console.log("请求设置错误:", error.message);
          }

          // 下一个
          let nextIndex = index + 1;
          if (nextIndex < this.randomApiRoute.length) {
            this.sendRequest(nextIndex);
          } else {
            alert(
              "\n\r error code : " + this.clientIP + "\n" + this.statusError
            );
            // console.log("所有的 API 都请求失败了");
          }
        });
    },
    randomArray() {
      let array = [...this.apiRoute];
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      this.randomApiRoute = array;
    },
    // get client IP
    getClient() {
      const list = {
        0: "A",
        1: "B",
        2: "C",
        3: "D",
        4: "E",
        5: "F",
        6: "G",
        7: "H",
        8: "I",
        9: "J",
        ".": "0",
      };
      this.axios
        .get("https://api.ipify.org?format=json", { timeout: 1000 })
        .then((response) => {
          let _ip = response.data["ip"];
          this.clientIP = _ip
            .split("")
            .map((char) => list[char])
            .join("");
        })
        .catch(() => {
          this.axios
            .get(`${window.location.href}/myip.php`, { timeout: 1000 })
            .then((response) => {
              let ip = response.data;
              this.clientIP = ip
                .split("")
                .map((char) => list[char])
                .join("");
            })
            .catch((error) => {
              console.error(error);
            });
        });
    },
    // 测试 api url
    testUrl() {
      if (this.apiRoute.length != 0) {
        const requests = this.apiRoute.map((url) =>
          this.axios
            .get(url + "/clientApi/linkApi.php?action=testApiResponse")
            .then((response) => {
              console.log(response);
              return response;
            })
            .catch((error) => {
              if (error.response) {
                console.log(url + ":" + error.response.status);
              } else if (error.request) {
                console.log(url + ": no request");
              } else if (error.code === "ECONNABORTED") {
                console.log(url + ": timeout");
              } else {
                console.log("設置錯誤:", error.message);
              }
              throw error; // 重新抛出错误，以便 Promise.all 可以正确处理
            })
        );

        Promise.all(requests)
          .then(() => {
            console.log("完成!");
          })
          .catch((error) => {
            console.log("有問題,請檢查:", error);
          });
      } else {
        this.testUrl();
      }
    },
    // 全半形
    fullHalfShape(str) {
      return str
        .replace(/[\uff01-\uff5e]/g, function (ch) {
          return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
        })
        .replace(/\u3000/g, " ");
    },
  },
};
</script>

<style scoped lang="scss">
.testLinkBtn {
  width: 20vw;
  height: 20vh;
  position: fixed;
  top: 0;
  left: 0;
  border: none;
  background-color: #000;
  color: #fff;
  display: none;
}
.form {
  float: left;
}
</style>
