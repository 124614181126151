import Vue from "vue";
import App from "./App.vue";
import "@/assets/css/style.scss";
import axios from "axios";
import VueAxios from "vue-axios";
import md5 from "md5";

// axios.defaults.baseURL = "http://api.example.local/";

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.prototype.$md5 = md5;

new Vue({
  render: (h) => h(App),
}).$mount("#app");

axios.defaults.timeout = 3 * 60 * 1000;
